<template>
    <div class="row">
        <div class="col q-gutter-y-sm">
            <q-select
                v-model="idCompagnie"
                :options="compagnies"
                option-value="idCompagnie"
                option-label="nom"
                label="Compagnie"
                :loading="isLoadingCompagnies"
                @update:model-value="idCompagnieChanged"
                clearable
                map-options
                emit-value
                options-dense
                dense
            />
            <q-select
                v-if="idCompagnie"
                v-model="idSite"
                :options="sites"
                option-value="idSite"
                option-label="nom"
                label="Site"
                :loading="isLoadingSites"
                @update:model-value="idSiteChanged"
                clearable
                map-options
                emit-value
                options-dense
                dense
            />
            <q-select
                v-if="idSite"
                v-model="idDepartement"
                :options="departements"
                option-value="idDepartement"
                option-label="nom"
                label="Département"
                :loading="isLoadingDepartements"
                @update:model-value="idDepartementChanged"
                clearable
                map-options
                emit-value
                options-dense
                dense
            />
            <q-select
                v-if="idDepartement"
                v-model="selectedEmploye"
                :options="employes"
                option-value="idEmploye"
                :option-label="
                    (x) =>
                        `${x.nomEmploye} (${
                            x.postes
                                .split('|')
                                .filter((p) => !!p)
                                .join(', ') || 'NA'
                        })`
                "
                label="Employé"
                :loading="isLoadingEmployes"
                clearable
                options-dense
                dense
                @update:model-value="selectedEmployeChanged"
            />
            <q-btn color="primary" label="Ajouter l'intervenant" :disable="!selectedEmploye" @click="ajouterCourrielDansListe" />
        </div>
    </div>
</template>

<script>
export default {
    name: "e-section-autre",
    emits: ["ajouter", "employe-selected"],
    data() {
        return {
            isLoadingCompagnies: false,
            compagnies: [],
            idCompagnie: null,
            isLoadingSites: false,
            sites: [],
            idSite: null,
            isLoadingDepartements: false,
            departements: [],
            idDepartement: null,
            isLoadingEmployes: false,
            employes: [],
            selectedEmploye: null,
            solutionRequiseLe: ""
        };
    },
    methods: {
        async getCompagnies() {
            this.isLoadingCompagnies = true;

            try {
                this.compagnies = await this.$api.contrats.getCompagniesProbleme();
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des compagnies." });
            } finally {
                this.isLoadingCompagnies = false;
            }
        },
        idCompagnieChanged(idCompagnie) {
            this.idSite = null;
            this.idSiteChanged(null);

            this.sites = [];

            if (idCompagnie) {
                this.getSites(idCompagnie);
            }
        },
        async getSites(idCompagnie) {
            this.isLoadingSites = true;

            try {
                this.sites = await this.$api.contrats.getSitesProbleme({ idCompagnie });
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des sites." });
            } finally {
                this.isLoadingSites = false;
            }
        },
        idSiteChanged(idSite) {
            this.idDepartement = null;
            this.idDepartementChanged(null);

            this.departements = [];

            if (idSite) {
                this.getDepartements(idSite);
            }
        },
        async getDepartements(idSite) {
            this.isLoadingDepartements = true;

            try {
                this.departements = await this.$api.contrats.getDepartementsProbleme({ idSite });
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des départements." });
            } finally {
                this.isLoadingDepartements = false;
            }
        },
        idDepartementChanged(idDepartement) {
            this.selectedEmploye = null;
            this.employes = [];

            if (idDepartement) {
                this.getEmployes(idDepartement);
            }
        },
        async getEmployes(idDepartement) {
            this.isLoadingEmployes = true;

            try {
                this.employes = await this.$api.contrats.getEmployesProbleme({ idDepartement });
            } catch (error) {
                this.$q.notify({ type: "negative", message: "Une erreur est survenue lors du chargement des employés." });
            } finally {
                this.isLoadingEmployes = false;
            }
        },
        ajouterCourrielDansListe() {
            this.selectedEmploye.Nom = this.selectedEmploye.nomEmploye;
            this.$emit("ajouter", this.selectedEmploye);
        },
        selectedEmployeChanged(selectedEmploye) {
            this.$emit("employe-selected", selectedEmploye);
        }
    },
    mounted() {
        this.getCompagnies();
    }
};
</script>
